import {createSlice} from "@reduxjs/toolkit";


export const langSlice = createSlice({
  name: 'lang',
  initialState: {
    lang: 'en',
  },
  reducers: {
    changeLang: (state, action) => {
      state.lang = action.payload;
    },
  },
})

export const { changeLang } = langSlice.actions;

export default langSlice.reducer;